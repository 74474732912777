<template>
    <b-form @submit.prevent="formSubmit" class="av-tooltip tooltip-label-bottom">
        <b-form-group :label='translation.translate("login","nomerTelefona")'
                      class="has-float-label mb-4 phone-field">
            <div class="input-group">
                <div class="input-group-prepend"><span
                    :class="{ 'invalid-phone': $v.form.username.$error }"
                    class="input-group-text">+998</span></div>
                <the-mask v-model="form.username" type="tel"
                          :class="{ 'invalid-phone': $v.form.username.$error }"
                          mask="## ### ## ##" placeholder="-- --- -- --"/>
            </div>
            <small class="text-danger"
                   v-if="$v.form.username.$error && !$v.form.username.required">
                {{ translation.translate("frequentlyRepeated", "etoPoleObyazatelnoDlyaZapolneniya") }}
            </small>
            <small class="text-danger"
                   v-if="$v.form.username.$error && $v.form.username.required && !$v.form.username.phoneWithout998">
                {{ translation.translate("frequentlyRepeated", "nepravilniyFormat") }}
            </small>
        </b-form-group>
        <b-form-group :label=' translation.translate("login","parol")' class="has-float-label mb-4">
            <b-form-input type="password" v-model="$v.form.password.$model"
                          :state="!$v.form.password.$error"/>
            <small class="text-danger" v-if="$v.form.password.$error && !$v.form.password.required">
                {{ translation.translate("frequentlyRepeated", "etoPoleObyazatelnoDlyaZapolneniya") }}
            </small>
        </b-form-group>
        <div class="d-flex justify-content-between align-items-center">
            <b-button type="submit" variant="primary" size="lg" :disabled="processing">
                {{ translation.translate("login", "vhod") }}
            </b-button>
        </div>
    </b-form>
</template>
<script>
import {
    mapGetters,
    mapActions
} from "vuex";
import {validationMixin} from "vuelidate";
import {TheMask} from 'vue-the-mask';
import {phoneWithout998} from '@/plugins/custom-validators';
import translation from "@/translation/translate";

const {required, maxLength, minLength} = require("vuelidate/lib/validators");

export default {
    data() {
        return {
            form: {
                username: "",
                password: ""
            },
            translation: translation,
        };
    },
    components: {
        TheMask
    },
    mixins: [validationMixin],
    validations: {
        form: {
            username: {
                required,
                phoneWithout998
            },
            password: {
                required
            }
        }
    },
    computed: {
        ...mapGetters(["currentUser", "processing", "loginError", "getMenus"])
    },
    methods: {
        ...mapActions(["login"]),
        formSubmit() {
            let vm = this;
            vm.$v.$touch();
            vm.$v.form.$touch();
            if (!vm.$v.form.$invalid) {
                vm.login({
                    username: '998' + vm.form.username,
                    password: vm.form.password
                }).then(function () {
                    vm.$router.push("/");
                });
            }
        },
    },
};
</script>
<style>
.btn-vue {
    background: #53B985;
    color: #31485D;
    font-weight: bold;
}
</style>