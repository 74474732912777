<template>
    <router-view/>
</template>

<script>
export default {
    name: "index"
}
</script>

<style scoped>

</style>